import React, { Component } from 'react';
import { withRouter } from 'react-router';
import reportedIssueService from './ReportedIssueService';
import { Button } from 'reactstrap';

class ReportedIssueDeletePlain extends Component {
	constructor(props) {
		super(props);
		this.state = { users: [], loading: true };

		const { match } = this.props;
		this.id = match.params.id;
	}

	componentDidMount() {

	}

	handleClickOk = () => {
		const { history } = this.props;

		(async () => {
			await reportedIssueService.delete(this.id);
			history.push('/reportedissues');
		})();
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/reportedissues');
	}

	render() {
		return (
			<div>
				<h2>Are you sure you want to delete issue ?</h2>

				<button onClick={this.handleClickOk}>Yes</button>
				<button onClick={this.handleClickCancel}>No</button>
			</div>
		);
	}
}
export const ReportedIssueDelete = withRouter(ReportedIssueDeletePlain);