import React, { Component } from 'react';
import { withRouter } from 'react-router';
import volunteerGroupService from './VolunteerGroupService';
import userService from '../users/UserService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { HuePicker, SketchPicker } from 'react-color';
import { AsyncPaginate } from 'react-select-async-paginate';

class VolunteerGroupEditPlain extends Component {
	constructor(props) {
		super(props);
		this.state = { entity: null, loading: true };

		const { match } = this.props;
		this.id = match.params.id;
	}

	componentDidMount() {
		this.retrieveFormData();
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.state.entity[name] = value;
		this.setState({ entity: this.state.entity});
	}

	handleVolunteersSelectChange(e) {
		this.state.entity["volunteersvalue"] = e;
		this.setState({ entity: this.state.entity });
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/volunteergroups');
	}

	handleValidSubmit = (event, values) => {
		const { history } = this.props;
		let processedValues = values;
		processedValues.colour = this.state.entity.colour;
		let volunteers = [];
		for (let person of this.state.entity["volunteersvalue"]) {
			volunteers.push({ id: person.value });
		}
		processedValues["volunteers"] = volunteers;
		(async () => {
			await volunteerGroupService.update(this.id, processedValues);
			history.push('/volunteergroups');
		})();
	}
	handleColourChange = (colour) => {
		this.state.entity.colour = colour.hex;
		this.setState({ entity: this.state.entity });
	}
	handleColourTextChange = (value) => {
		console.log(value.target.value);
		this.state.entity.colour = value.target.value;
		this.setState({ entity: this.state.entity });
    }

	renderForm(entity) {
		const { t, i18n } = this.props;
		return (
			<AvForm model={entity} onValidSubmit={this.handleValidSubmit}>
				<AvField name="id" type="hidden" />
				<AvField name="rowVersion" type="hidden" />
				<AvField name="name" label={t('Name')} required errorMessage={t('FieldInvalid')} validate={{
					required: { value: true, errorMessage: t('FieldRequired') },
					minLength: { value: 2}
				}} />
				<FormGroup>
					<Input type="text" name="colour" onChange={this.handleColourTextChange} value={entity.colour} style={{ backgroundColor: entity.colour }} />
				</FormGroup>
				<HuePicker color={entity.colour == null ? '' : entity.colour} onChange={this.handleColourChange} />
				<FormGroup>
					<Label id="volunteers-label" htmlFor="volunteers">
						{t('Volunteers')}
					</Label>
					<AsyncPaginate
						value={this.state.entity.volunteersvalue }
						loadOptions={async (search, loadedVolunteersOptions, { page }) => {
							const responseJSON = await userService.selectlist(search, page, 0);
							return {
								options: responseJSON.result,
								hasMore: responseJSON.hasmore,
								additional: {
									page: page + 1,
								},
							};
						}}
						onChange={this.handleVolunteersSelectChange.bind(this)}
						additional={
							{ page: 1, }
						}
						isMulti='true'
					/>
				</FormGroup>
				<p></p>
				<FormGroup>
					<Button>Save</Button>&nbsp;
					<Button onClick={this.handleClickCancel}>Cancel</Button>
				</FormGroup>
			</AvForm>
		);
	}

	render() {
		let contents = this.state.loading
			? <p><em>Loading...</em></p>
			: this.renderForm(this.state.entity);

		return (
			<div>
				<h1 id="tabelLabel">Volunteer Group</h1>
				{contents}
			</div>
		);
	}

	async retrieveFormData() {
		const data = await volunteerGroupService.get(this.id);
		let volunteers = [];
		for (let person of data.volunteers) {
			volunteers.push({ value: person.id, label: person.firstname + " " + person.surname });
		}
		data.volunteersvalue = volunteers;
		this.setState({ entity: data, loading: false });
	}
}

export const VolunteerGroupEdit = withTranslation()(withRouter(VolunteerGroupEditPlain));