import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';

import { UserActivate } from './UserActivate';
import { UserList } from './UserList';
import { UserAdd } from './UserAdd';
import { UserDelete } from './UserDelete';
import { UserEdit } from './UserEdit';
import { UserPasswordChange } from './UserPasswordChange';

export function UserRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}/activate/:userId?`} component={UserActivate} />
			<AuthorizeRoute path={`${match.path}/add`} component={UserAdd} />
			<Route path={`${match.path}/delete/:userId`} component={UserDelete} />
			<AuthorizeRoute path={`${match.path}/edit/:userId`} component={UserEdit} />
			<AuthorizeRoute path={`${match.path}/password-change/:userId`} component={UserPasswordChange} />
			<AuthorizeRoute path={`${match.path}`} component={UserList} />
		</Switch>
	);
}