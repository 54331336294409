import React, { Component } from 'react';
import workProgramService from './WorkProgramService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import userService from '../users/UserService';
import pathService from '../paths/PathService';
import pathStateService from '../pathstates/PathStateService';
import authService from '../api-authorization/AuthorizeService'
import pathWorkService from '../pathworks/PathWorkService';
import volunteerGroupService from '../volunteergroups/VolunteerGroupService';
import { VolunteerGroupOption, VolunteerGroupValueContainer } from '../volunteergroups/VolunteerGroupSelect'

class WorkProgramAddPlain extends Component {

	constructor(props) {
        super(props);
        this.state = {
            leadervalue: null,
            goingvalue: [],
            pathstatevalue: null,
            pathvalue: null,
            workrequiredvalue: null,
            volunteergroupvalue: null,

            isAuthenticated: false,
            role: null,
            user: null,
            firstname: null,
        }
	}

    componentDidMount() {
        this.populateUserState();
	}
    async populateUserState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
        ]);
        if (user) {
            this.setState({ leadervalue: { "label": user.firstname + " " + user.surname, "value": user.sub } })
        }
        this.setState({
            isAuthenticated,
            role: user && user.role,
            user: user,
            firstname: user && user.firstname
        });
    }
	handleClickCancel = () => {
		const { history } = this.props;

		//history.push('/workprograms');
        history.goBack();
	}

    handleLeaderSelectChange(e) {
        this.setState({ leadervalue: { value: e.value, label: e.label } });
    }

    handleGoingSelectChange(e) {
        this.setState({ goingvalue: e });
    }

    handlePathSelectChange(e) {
        this.setState({ pathvalue:{ value: e.value, label: e.label } });
    }

    handlePathStateSelectChange(e) {
        this.setState({ pathstatevalue: { value: e.value, label: e.label } });
    }

    handleWorkRequiredSelectChange(e) {
        this.setState({ workrequiredvalue: { value: e.value, label: e.label } });
    }
    handleVolunteerGroupSelectChange(e) {
        this.setState({ volunteergroupvalue: { value: e.value, label: e.label, colour: e.colour }});
    }
 

	handleValidSubmit = (event, values) => {
		const { history } = this.props;

        let postvalues = values;

        /*
         * goingvalue
         * leadervalue
         * workrequiredvalue
         * pathvalue
         * pathstatevalue
         * volunteergroupvalue
        */
        let going = [];

        for (let person of this.state.goingvalue) {
            going.push({ id: person.value });
        }
        postvalues["going"] = going;

        if (this.state.leadervalue) {
            postvalues["leader"] = { id: this.state.leadervalue.value };
        }
        if (this.state.pathvalue) {
            postvalues["path"] = { id: this.state.pathvalue.value };
        }
        if (this.state.pathstatevalue) {
            postvalues["pathState"] = { id: this.state.pathstatevalue.value };
        }
        if (this.state.workrequiredvalue) {
            postvalues["workRequired"] = { id: this.state.workrequiredvalue.value };
        }
        if (this.state.volunteergroupvalue) {
            postvalues["volunteerGroup"] = { id: this.state.volunteergroupvalue.value };
        }
        //debugger;
        if (postvalues.timeSpentHours == "" || isNaN(postvalues.timeSpentHours)) {
            postvalues.timeSpentHours = 0;
        }
		(async () => {
			await workProgramService.add(postvalues);
			//history.push('/workprograms');
            history.goBack();
		})();
	}

	render() {
		const { t, i18n } = this.props;
		return (
			<AvForm onValidSubmit={this.handleValidSubmit}>
                <AvField name="title" label={t('Title')} required errorMessage={t('FieldInvalid')} validate={{
                    required: { value: true, errorMessage: t('FieldRequired') },
                    minLength: { value: 2 }
                }} />
                <FormGroup>
                    <Label id="leader-label" htmlFor="leader">
                        {t('Leader')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.leadervalue}
                        loadOptions={async (search, loadedLeaderOptions, { page }) => {
                            const responseJSON = await userService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handleLeaderSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                    />

                    {/*<SelectFetch*/}
                    {/*    name="leader"*/}
                    {/*    aria-labelledby="leader-label"*/}
                    {/*    inputId="leader"*/}
                    {/*    value={this.state.entity.leadervalue}*/}
                    {/*    url='/users/selectlist'*/}
                    {/*    mapResponse={(response) => ({*/}
                    {/*        options: response.result,*/}
                    {/*        hasMore: response.hasmore*/}
                    {/*    })}*/}
                    {/*    //options={this.state.selectOptions}*/}
                    {/*    onChange={this.handleLeaderSelectChange.bind(this)}*/}
                    {/*/>*/}
                </FormGroup>
                <FormGroup>
                    <Label id="volunteerGroup-label" htmlFor="volunteerGroup">
                        {t('VolunteerGroup')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.volunteergroupvalue}
                        loadOptions={async (search, loadedVolunteerGroupOptions, { page }) => {
                            const responseJSON = await volunteerGroupService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handleVolunteerGroupSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                        components={{
                            Option: VolunteerGroupOption,
                            ValueContainer: VolunteerGroupValueContainer
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label id="going-label" htmlFor="going">
                        {t('Going')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.goingvalue}
                        loadOptions={async (search, loadedGoingOptions, { page }) => {
                            const responseJSON = await userService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handleGoingSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                        isMulti='true'
                    />
                </FormGroup>

                <FormGroup>
                    <Label id="path-label" htmlFor="path">
                        {t('Path')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.pathvalue}
                        loadOptions={async (search, loadedPathOptions, { page }) => {
                            const responseJSON = await pathService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handlePathSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                    />
                </FormGroup>

                <FormGroup>
                    <Label id="workrequired-label" htmlFor="workrequired">
                        {t('WorkRequired')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.workrequiredvalue}
                        loadOptions={async (search, loadedPathOptions, { page }) => {
                            const responseJSON = await pathWorkService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handleWorkRequiredSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                    />
                </FormGroup>

                <AvField name="start" label={t('StartDate')} type="date" required />
                <AvField name="end" label={t('EndDate')} type="date" required />
                <AvField name="gridref" label={t('Gridref')} />
                <AvField name="location" label={t('Location')} />
                <AvField name="information" label={t('Information')} type="textarea" />

                <FormGroup>
                    <Label id="pathstate-label" htmlFor="pathstate">
                        {t('PathState')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.pathstatevalue}
                        loadOptions={async (search, loadedPathStateOptions, { page }) => {
                            const responseJSON = await pathStateService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handlePathStateSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                    />
                </FormGroup>

                <AvField name="surveyComment" label={t('SurveyComment')} type="textarea" />
                <AvField name="timeSpentHours" label={t('TimeSpent')} type="number" />
				<FormGroup>
					<Button>Save</Button>&nbsp;
					<Button onClick={this.handleClickCancel}>Cancel</Button>
				</FormGroup>
			</AvForm>
		);
	}
}

export const WorkProgramAdd = withTranslation()(WorkProgramAddPlain);
