import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import pathStateService from './PathStateService';
import { Pager } from '../Pager';
import { FormGroup, Form, Input, Button } from 'reactstrap';
import { FaKey,FaSortAlphaDown,FaSortAlphaUp,FaEdit,FaTrash } from 'react-icons/fa';

export class PathStateList extends Component {
	constructor(props) {
		super(props);
		this.state = { entities: [], page: 1, pageSize: 10, maxPages: 5, sortOrder: "State", searchString: "", loading: true };
	}

	componentDidMount() {
		this.populateData();
	}

	handlePageChange = (page) => {
		this.setState({ page: page }, () => this.populateData() );
	}

	handleHeaderClick = (event, header) => {
		event.preventDefault();

		let newSortOrder = this.state.sortOrder;

		switch (header) {
			case 'State': {
				newSortOrder = this.state.sortOrder === 'State' ? 'State_desc' : 'State';
				break;
			}
		}

		this.setState({ page: 1, sortOrder: newSortOrder }, () => this.populateData());
		return false;
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name]: value });
	}

	handleSearchFormSubmit = (event) => {
		event.preventDefault();
		this.setState({ page: 1 }, () => this.populateData());
		//this.populateData();
	}

	handleSearchFormReset = (event) => {
		event.preventDefault();
		this.setState({ page:1, searchString: "" }, () => this.populateData());
	}

	renderTable() {
		const { entities, total, sortOrder } = this.state;

		return (
			<div>
				<table className='table table-striped' aria-labelledby="tableLabel">
					<thead>
						<tr>
							<th>
								<a href="#" onClick={(e) => this.handleHeaderClick(e, 'State')} >
									State&nbsp;
									{sortOrder == 'State' && <FaSortAlphaDown /> }
									{sortOrder == 'State_desc' && <FaSortAlphaUp /> }
								</a>
							</th>
							<th />
							<th />
						</tr>
					</thead>
					<tbody>
						{entities.map(entity =>
							<tr key={entity.id}>
								<td>{entity.state}</td>
								<td><Link to={'/pathstates/delete/' + entity.id}><FaTrash /></Link></td>
								<td><Link to={'/pathstates/edit/' + entity.id}><FaEdit /></Link></td>
							</tr>
						)}
					</tbody>
				</table>
				<Pager totalItems={total} page={this.state.page} pageSize={this.state.pageSize} maxPages={this.state.maxPages} handlePageChange={this.handlePageChange} />
			</div>
		);
	}

	render() {
		let contents = this.state.loading
			? <p><em>Loading...</em></p>
			: this.renderTable();

		return (
			<div>
				<h1 id="tableLabel">Path States</h1>
				<Link to='/pathstates/add/'>Add Path State</Link>
				<Form inline onSubmit={this.handleSearchFormSubmit}>
					<FormGroup>
						<Input type="text" name="searchString" value={this.state.searchString} onChange={this.handleInputChange} placeholder="Search text" />
					</FormGroup>&nbsp;
					<Button>Search</Button>&nbsp;
					<Button onClick={this.handleSearchFormReset}>Reset</Button>
				</Form><br/>
				{contents}
			</div>
		);
	}

	async populateData() {
		try {

			const data = await pathStateService.getData(this.state.page, this.state.pageSize, this.state.sortOrder, this.state.searchString);
			this.setState({ total: data.total, entities: data.entities, loading: false });
		}
		catch (error) {
			// Handle error
		}
	}
}