import React, { Component } from 'react';
import { withRouter } from 'react-router';
import reportedIssueService from './ReportedIssueService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button, Table, CardImg } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { HuePicker, SketchPicker } from 'react-color';
import GridrefSelector from '../pathmap/GridrefSelector';

class ReportedIssueEditPlain extends Component {
	constructor(props) {
		super(props);
		this.state = { entity: null, loading: true };

		const { match } = this.props;
		this.id = match.params.id;
	}

	componentDidMount() {
		this.retrieveFormData();
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.state.entity[name] = value;
		this.setState({ entity: this.state.entity});
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/reportedissues');
	}

	handleValidSubmit = (event, values) => {
		const { history } = this.props;
		let processedValues = values;

		(async () => {
			await reportedIssueService.update(this.id, processedValues);
			history.push('/reportedissues');
		})();
	}
	

	renderForm(entity) {
		const { t, i18n } = this.props;
		return (
			<AvForm model={entity} onValidSubmit={this.handleValidSubmit}>
				<AvField name="id" type="hidden" />
				<AvField name="rowVersion" type="hidden" />
				<Table>
					<tbody>
						{this.state.entity.path ? (<tr>
							<th>Path</th>
							<td>{this.state.entity.path.name}</td>
						</tr>) : null}
						<tr>
							<td colSpan="2"><GridrefSelector value={this.state.entity.gridref} isStatic={true}/></td>
						</tr>
						<tr>
							<th>Gridref</th>
							<td>{this.state.entity.gridref}</td>
						</tr>
						<tr>
							<th>Latitude</th>
							<td>{this.state.entity.latitude.toFixed(5)}</td>
						</tr>
						<tr>
							<th>Longitude</th>
							<td>{this.state.entity.longitude.toFixed(5)}</td>
						</tr>
						<tr>
							<th>Comment</th>
							<td>{this.state.entity.comment}</td>
						</tr>
						<tr>
							<th>Email</th>
							<td>{this.state.entity.email}</td>
						</tr>
						{this.state.entity.photo ? (
						<tr><td colSpan="2">
							<CardImg
								alt="Photo"
								bottom
								src={'/issueimages/'+this.state.entity.photo}
								width="100%"
							/>
							</td></tr>
						) : null}
					</tbody>
				</Table>
				<FormGroup>
					<Button>Save</Button>&nbsp;
					<Button onClick={this.handleClickCancel}>Cancel</Button>
				</FormGroup>
			</AvForm>
		);
	}

	render() {
		let contents = this.state.loading
			? <p><em>Loading...</em></p>
			: this.renderForm(this.state.entity);

		return (
			<div>
				<h1 id="tabelLabel">Reported Issue</h1>
				{contents}
			</div>
		);
	}

	async retrieveFormData() {
		const data = await reportedIssueService.get(this.id);
		this.setState({ entity: data, loading: false });
	}
}

export const ReportedIssueEdit = withTranslation()(withRouter(ReportedIssueEditPlain));