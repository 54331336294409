import React, { Component } from 'react';
import { withRouter } from 'react-router';
import userService from './UserService';
import volunteerGroupService from '../volunteergroups/VolunteerGroupService';
import { AvForm, AvField,AvInput } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { withTranslation } from 'react-i18next';


class UserEditPlain extends Component {
	constructor(props) {
		super(props);
		this.state = { user: null, loading: true };

		const { match } = this.props;
		this.userId = match.params.userId;
	}

	componentDidMount() {
		this.retrieveFormData();
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.state.user[name] = value;
		this.setState({ user: this.state.user });
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/users');
	}
	handleVolunteerGroupsSelectChange(e) {
		this.state.user["volunteerGroupsvalue"] = e;
		this.setState({ entity: this.state.user });
    }
	handleValidSubmit = (event, values) => {
		const { history } = this.props;
		let postvalues = values;

		let volunteerGroups = [];
		for (let group of this.state.user["volunteerGroupsvalue"]) {
			volunteerGroups.push({ id: group.value });
		}
		postvalues["volunteerGroups"] = volunteerGroups;


		(async () => {
			await userService.updateUser(this.userId, postvalues);
			history.push('/users');
		})();
	}

	renderUserForm(user) {
		const { t, i18n } = this.props;
		return (
			<AvForm model={user} onValidSubmit={this.handleValidSubmit}>
				<AvField name="id" type="hidden" />
				<AvField name="rowVersion" type="hidden" />
				<AvField name="firstname" label={t('FirstName')} required errorMessage={t('FieldInvalid')} validate={{
					required: { value: true, errorMessage: t('FieldRequired') },
					minLength: { value: 2}
				}} />
				<AvField name="surname" label="Last name" required />
				<AvField name="email" type="email" label="Email" required />
				<AvField name="role" type="select" label="Role" required>
                    <option value="Administrator">Administrator</option>
					<option value="Volunteer Leader">Volunteer Leader</option>
					<option value="Volunteer">Volunteer</option>
                    <option value="Recruit">Recruit</option>
				</AvField>
				<FormGroup>
					<Label id="volunteers-label" htmlFor="volunteers">
						{t('Volunteers')}
					</Label>
					<AsyncPaginate
						value={this.state.user.volunteerGroupsvalue}
						loadOptions={async (search, loadedVolunteerGroupsOptions, { page }) => {
							const responseJSON = await volunteerGroupService.selectlist(search, page, 0);
							return {
								options: responseJSON.result,
								hasMore: responseJSON.hasmore,
								additional: {
									page: page + 1,
								},
							};
						}}
						onChange={this.handleVolunteerGroupsSelectChange.bind(this)}
						additional={
							{ page: 1, }
						}
						isMulti='true'
					/>
				</FormGroup>
				<AvField label="Send daily email ?" type="checkbox" name="dailyEmail"  />
				<FormGroup>
					<Button>Save</Button>&nbsp;
					<Button onClick={this.handleClickCancel}>Cancel</Button>
				</FormGroup>
			</AvForm>
		);
	}

	render() {
		let contents = this.state.loading
			? <p><em>Loading...</em></p>
			: this.renderUserForm(this.state.user);

		return (
			<div>
				<h1 id="tabelLabel">Users</h1>
				{contents}
			</div>
		);
	}

	async retrieveFormData() {
		const data = await userService.getUser(this.userId);

		let volunteerGroups = [];
		for (let group of data.volunteerGroups) {
			volunteerGroups.push({ value: group.id, label: group.name });
		}
		data.volunteerGroupsvalue = volunteerGroups;

		this.setState({ user: data, loading: false });
	}
}

export const UserEdit = withTranslation()(withRouter(UserEditPlain));