import {
    format,
    parse,
    startOfWeek,
    endOfWeek,
    getDay,
    startOfMonth,
    endOfMonth,
    addDays,
    formatISO9075,
} from 'date-fns';
import { enGB } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { FaAngleLeft, FaAngleRight, } from 'react-icons/fa';
import { Calendar, dateFnsLocalizer, BigCalendar } from 'react-big-calendar';
import cn from 'classnames';
//import Toolbar from 'react-big-calendar';
import workProgramService from '../workprograms/WorkProgramService';
import React, { Component, Link } from 'react';
import authService from '../api-authorization/AuthorizeService';
import GridrefSelector from '../pathmap/GridrefSelector';
import {
    FormGroup, Form, Label, Input, Button,
    Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, ButtonGroup,
    InputGroup
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import "react-big-calendar/lib/css/react-big-calendar.css";
const locales = {
    'en-GB': enGB//require('date-fns/locale/en-GB'),
}
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => {
        startOfWeek(new Date(), { weekStartsOn: 1 })
    }, // week start on 0-sun 1-mon
    getDay,
    locales,
});
const propTypes = {
    view: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.node.isRequired,
    localizer: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired
};
class CustomToolbar extends Component {
    static propTypes = propTypes;
    view = view => {
        this.props.onView(view);
    };
    render() {
        let {
            localizer: { messages },
            label,
            date
        } = this.props;
        return (
            <div className='rbc-toolbar'>
                <span className="rbc-btn-group">
                    <Button className='btn-primary' type="button" onClick={() => this.navigate('PREV')}><FaAngleLeft /> Back</Button>
                    <Button type="button" onClick={() => this.navigate('TODAY')} >Today</Button>
                    <Button type="button" onClick={() => this.navigate('NEXT')}>Next <FaAngleRight /></Button>
                </span>
                <span className="rbc-toolbar-label">{this.props.label}</span>
                <span className="rbc-btn-group">
                    {this.viewNamesGroup(messages)}
                </span>
            </div>
        );
    }
    viewNamesGroup(messages) {
        let viewNames = this.props.views;
        const view = this.props.view;

        if (viewNames.length > 1) {
            return viewNames.map(name => (
                <Button
                    key={name}
                    className={cn({
                        active: view === name,
                        'btn-primary': view === name
                    })}
                    onClick={this.view.bind(null, name)}
                >
                    {messages[name]}
                </Button>
            ));
        }
    }
    navigate = action => {
        console.log(action);

        this.props.onNavigate(action)
    }
}




class WorkProgramCalendarPlain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentMonth: startOfMonth(new Date()),
            events: [

            ],
            selectedevent: {
                leader: null,
                going: []
            },
            showselectedevent: false,
            applicationcomment: '',

            isAuthenticated: false,
            ready: false,
            role: null,
            user: null,
            firstname: null,
            datadate: null,
            limitToMyWorkPrograms: false,
            limitToMyGroup: true,

            emailsubject: "",
            emailtext:""
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleEmailModal = this.toggleEmailModal(this);
    }

    toggleModal(e) {
        this.setState({
            showModal: !this.state.showModal
        });
    }
    toggleEmailModal(e) {
        this.setState({ showEmailModal: !this.state.showEmailModal });
    }

    componentDidMount() {
        //this.populateUserState();
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }
    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const user = await authService.getUser();
        this.setState({ ready: true, isAuthenticated: authenticated, user, role: user && user.role, firstname: user && user.firstname });
        this.userStateReturned();
    }
    userStateReturned() {
        this.populateData(new Date());
    }
    //async populateUserState() {
    //    const [isAuthenticated, user] = await Promise.all([
    //        authService.isAuthenticated(),
    //        authService.getUser(),
    //    ]);
    //    this.setState({
    //        isAuthenticated,
    //        role: user && user.role,
    //        user: user,
    //        firstname: user && user.firstname
    //    });
    //    this.userStateReturned();
    //}
    async joinWorkProgram(workProgramId, userId) {
        const data = await workProgramService.joinWorkProgram(workProgramId, userId);
        if (data.result) {
            await this.populateData(this.state.datadate);
            this.setState({
                selectedevent: this.state.events.find((o) => { return o.id == this.state.selectedevent.id })
            });
        }
        else {
            this.setState({ showselectedevent: false });
        }
    }
    async leaveWorkProgram(workProgramId, userId) {
        const data = await workProgramService.leaveWorkProgram(workProgramId, userId);
        if (data.result) {
            await this.populateData(this.state.datadate);
            this.setState({
                selectedevent: this.state.events.find((o) => { return o.id == this.state.selectedevent.id })
            });
        }
        else {
            this.setState({ showselectedevent: false });
        }
    }
    async populateData(date) {
        let start = formatISO9075(startOfWeek(startOfMonth(date)), { representation: 'date' });
        let end = formatISO9075(endOfWeek(endOfMonth(date)), { representation: 'date' });
        //console.log("start=" + start + ", end=" + end);
        try {
            const user = this.state.user;
            const data = user && this.state.role != 'Recruit' ?
                await workProgramService.calendareventlist(start, end)
                :
                await workProgramService.calendareventlistlimited(start, end);
            console.log("popdata=" + (user && this.state.role != 'Recruit'));
            data.result.forEach(function (item) {
                item.start = new Date(item.start);
                item.end = new Date(item.end);
                item.userisgoing = false;
                if (user) {
                    item.going.forEach(function (persongoing) {
                        if (user && persongoing.id == user.sub) {
                            item.userisgoing = true;
                        }
                    });
                }
            });
            console.log("###");
            this.setState({ events: data.result, loading: false, datadate: date });
        }
        catch (error) {
            // Handle error
            //console.log("popdataerror:" + error);
        }
    }
    async sendEmail(workProgramId, userId, emailSubject, emailText) {
        try {
            const data = await workProgramService.emailParticipants(workProgramId, userId, emailSubject, emailText);
        } catch (error) {}
    }
    eventStyleGetter(event, start, end, isSelected) {
        var backgroundColor = '' + (event.hexcolour.length > 0 ? event.hexcolour : '#7EB1D6');
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            fontWeight: event.userisgoing ? 'bold' : 'normal',
            display: 'block',
            //height: '22px'
        };
        return {
            className: "",
            style: style
        };
    }
    eventSelected = (e) => {
        //e.preventDefault();
        this.setState({ selectedevent: e, showselectedevent: true });

    }
    slotSelected() { }
    onAddWorkProgramClick = () => {
        const { history } = this.props;
        history.push('/workprograms/add/' + this.state.selectedevent.id);
    }
    onCancelClick = () => {

    }
    onDeleteClick = () => {
        const { history } = this.props;
        history.push('/workprograms/delete/' + this.state.selectedevent.id);
    }
    onEditClick = () => {
        const { history } = this.props;
        history.push('/workprograms/edit/' + this.state.selectedevent.id);
    }

    onEmailFormChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.setState({ [name]: value });
    }
    onShowSendEmail = () => {
        this.setState({ emailsubject: this.state.selectedevent.title, emailtext: "" });
        this.setState({ showEmailModal: true });
        //onsole.log(this.state.selectedevent.title);
    }
    onSendEmail = () => {
        let valid = true;
        if (this.state.emailsubject === "") {
            valid = false;
        }
        if (this.state.emailtext === "") {
            valid = false;
        }
        if (valid) {
            this.sendEmail(this.state.selectedevent.id, this.state.user.sub, this.state.emailsubject, this.state.emailtext);
            this.setState({ showEmailModal: false });
        }
    }
    onNavigate(date) {
        this.populateData(date);
    }
    onApplicationCommentChange(e) {
        this.setState({ applicationcomment: e });
    }
    onJoinWorkProgram = () => {
        this.joinWorkProgram(this.state.selectedevent.id, this.state.user.sub);
    }
    onLeaveWorkProgram = () => {
        this.leaveWorkProgram(this.state.selectedevent.id, this.state.user.sub);
    }
    onLimitToMyWorkPrograms = () => {
        this.setState({ limitToMyWorkPrograms: !this.state.limitToMyWorkPrograms })
    }
    onLimitToMyGroup = () => {
        this.setState({ limitToMyGroup: !this.state.limitToMyGroup })
    }
    render() {
        const { t, i18n } = this.props;
        const whosGoing = this.state.selectedevent.going == null ?
            []
            :
            [].concat(this.state.selectedevent.going)
                .sort((a, b) => a.name > b.name ? 1 : -1)
                .map((d) => d.id == this.state.user.sub ?
                    <div key={d.id}><b>{d.name}</b></div>
                    :
                    <div key={d.id}>{d.name}</div>
                );
        return (
            <div>
                {
                    this.state.user &&
                        (
                            this.state.role == 'Administrator' ||
                            this.state.role == 'Volunteer Leader'
                        )
                        ?
                        (
                            <FormGroup>
                                <Button className='btn-primary' onClick={this.onAddWorkProgramClick}>Add Work Program</Button>

                            </FormGroup>) : ""}
                {
                    this.state.user && this.state.role != 'Recruit' ?
                        (
                            <InputGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" defaultChecked={this.state.limitToMyWorkPrograms} onChange={this.onLimitToMyWorkPrograms} /> Only show my work programmes
                                    </Label>

                                </FormGroup>
                                &nbsp;
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" defaultChecked={this.state.limitToMyGroup} onChange={this.onLimitToMyGroup} /> Limit to my volunteer group(s)
                                    </Label>
                                </FormGroup>
                            </InputGroup>

                        ) : ""}

                <Calendar
                    localizer={localizer}
                    events={this.state.isAuthenticated ? this.state.events.filter((ev) =>
                        ((ev.userisgoing == true && this.state.limitToMyWorkPrograms) || !this.state.limitToMyWorkPrograms) &&
                        ((ev.mygroup == true && this.state.limitToMyGroup) || !this.state.limitToMyGroup)
                    )
                        : this.state.events
                    }
                    startAccessor="start"
                    endAccessor="end"
                    style={{ minHeight: 800 }}
                    defaultDate={new Date()}
                    onSelectSlot={(this.slotSelected)}
                    onSelectEvent={this.eventSelected}
                    eventPropGetter={(this.eventStyleGetter)}
                    onNavigate={this.onNavigate.bind(this)}
                    defaultView='month'
                    views={['month', 'week', 'agenda']}
                    popup={true}
                    components={{
                        toolbar: CustomToolbar
                    }}
                    culture={"en-GB"}
                />
                <Modal isOpen={this.state.showselectedevent} toggle={this.toggleModal} >
                    <ModalHeader>

                        {this.state.selectedevent == null ? "" : this.state.selectedevent.title}

                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="4"><b>Leader</b></Col>
                            <Col xs="8">{this.state.selectedevent.leader == null ? "" : this.state.selectedevent.leader}</Col>
                        </Row>
                        <Row>
                            <Col xs="4"><b>Path</b></Col>
                            <Col xs="8">{this.state.selectedevent.path == null ? "" : this.state.selectedevent.path}</Col>
                        </Row>
                        <Row>
                            <Col xs="4"><b>Location</b></Col>
                            <Col xs="8">{this.state.selectedevent.location == null ? "" : this.state.selectedevent.location}</Col>
                        </Row>
                        <Row>
                            <Col xs="4"><b>Gridref</b></Col>
                            <Col xs="8">{this.state.selectedevent.gridref == null ? "" : this.state.selectedevent.gridref}</Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <GridrefSelector value={this.state.selectedevent.gridref} isStatic={true} hideInvalid={true}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="4"><b>Information</b></Col>
                            <Col xs="8">{this.state.selectedevent.information == null ? "" : this.state.selectedevent.information}</Col>
                        </Row>
                        {this.state.selectedevent.surveycomment != null && this.state.selectedevent.surveycomment != "" ? (
                            <Row>
                                <Col xs="4"><b>Survey Comment</b></Col>
                                <Col xs="8">{this.state.selectedevent.surveycomment == null ? "" : this.state.selectedevent.surveycomment}</Col>
                            </Row>
                        ) : ""}
                        {this.state.selectedevent.expired ? (
                            <Row>
                                <Col xs="4"><b>Time Spent</b></Col>
                                <Col xs="8">{this.state.selectedevent.timespenthours == null ? "" : this.state.selectedevent.timespenthours} hours</Col>
                            </Row>
                        ) : ""}
                        <Row>
                            <Col xs="4"><b>Start Date</b></Col>
                            <Col xs="8">{this.state.selectedevent.start == null ? "" : format(this.state.selectedevent.start, 'dd/MM/yyyy')}</Col>
                        </Row>
                        <Row>
                            <Col xs="4"><b>End Date</b></Col>
                            <Col xs="8">{this.state.selectedevent.end == null ? "" : format(this.state.selectedevent.end, 'dd/MM/yyyy')}</Col>
                        </Row>
                        {this.state.user && this.state.role != 'Recruit' ? (
                            <Row>
                                <Col xs="4"><b>Who's Going</b></Col>
                                <Col xs="auto">{whosGoing}</Col>
                            </Row>
                        ) : ""}
                        <Row>
                            <Col xs="4">&nbsp;</Col>
                            <Col xs="8"> </Col>
                        </Row>
                        {this.state.selectedevent.start == null ? "" : (
                            <Row>
                                <Col xs="12">Open <a href={"https://www.metoffice.gov.uk/weather/specialist-forecasts/mountain/brecon-beacons#?date=" + format(this.state.selectedevent.start, 'yyyy-MM-dd')} target="_blank">Met office forcast</a> in a new tab</Col>
                            </Row>
                        )}
                        {/*{*/}
                        {/*    this.state.user &&*/}
                        {/*        this.state.role != 'Recruit' &&*/}
                        {/*        (this.state.selectedevent.leader == null || this.state.user.sub != this.state.selectedevent.leader.id) &&*/}
                        {/*        !this.state.selectedevent.expired*/}
                        {/*        ? (*/}
                        {/*            <Row>*/}
                        {/*                <Col xs="4"><b>Application Comment</b></Col>*/}
                        {/*                <Col xs="8"><Input style={{ width: '100%' }} type='textarea' name='ApplicationComment' onChange={this.onApplicationCommentChange.bind(this)} /></Col>*/}
                        {/*            </Row>*/}
                        {/*        ) : ""}*/}
                        {
                            this.state.user &&
                                this.state.role != 'Recruit' &&
                                (this.state.selectedevent.leader == null || this.state.user.sub != this.state.selectedevent.leaderid) &&
                                !this.state.selectedevent.expired ?
                                (
                                    <div>Joining this event confirms you have read the <a href="https://1drv.ms/f/s!AtJNYS9qcazFmyZ_MwapaHdMuKa7" target="_blank">Risk Assessments</a></div>
                                ) : ""}
                    </ModalBody>

                    {
                        // normal user not the leader of the WP and not expired
                        this.state.user &&
                            this.state.role != 'Recruit' &&
                            (this.state.selectedevent.leader == null || this.state.user.sub != this.state.selectedevent.leaderid) &&
                            !this.state.selectedevent.expired ? (
                            <ModalFooter>
                                {this.state.selectedevent.going.filter((x) => x.id == this.state.user.sub).length == 0 ?
                                    (<Button className='btn-success' onClick={() => this.onJoinWorkProgram()}>Join</Button>)
                                    :
                                    (<Button className='btn-danger' onClick={() => this.onLeaveWorkProgram()}>Leave</Button>)
                                }
                                <Button className='btn-primary' onClick={() => this.setState({ showselectedevent: false })}>Close</Button>
                            </ModalFooter>
                        ) : ""}
                    {
                        // normal user not the leader of the WP and expired
                        this.state.user &&
                            this.state.role != 'Recruit' &&
                            (this.state.selectedevent.leader == null || this.state.user.sub != this.state.selectedevent.leaderid) &&
                            this.state.selectedevent.expired ? (
                            <ModalFooter>
                                <Button className='btn-primary' onClick={() => this.setState({ showselectedevent: false })}>Close</Button>
                            </ModalFooter>
                        ) : ""}

                    {
                        // leader of the WP and not expired
                        this.state.user &&
                            this.state.role != 'Recruit' && this.state.role != 'Volunteer' &&
                            /*(this.state.selectedevent.leader != null && this.state.user.sub == this.state.selectedevent.leaderid) &&*/
                            !this.state.selectedevent.expired ? (
                            <ModalFooter>
                                <Button className='btn-warning' onClick={this.onDeleteClick}>Cancel WP</Button>
                                <Button className='btn-info' onClick={this.onEditClick}>Edit</Button>
                                <Button className='btn-success' onClick={this.onShowSendEmail}>Email Group</Button>
                                <Button className='btn-primary' onClick={() => this.setState({ showselectedevent: false })}>Close</Button>
                            </ModalFooter>
                        ) : ""}
                    {
                        // leader of the WP and expired
                        this.state.user &&
                            this.state.role != 'Recruit' && this.state.role != 'Volunteer' &&
                            /*(this.state.selectedevent.leader != null && this.state.user.sub == this.state.selectedevent.leaderid) &&*/
                            this.state.selectedevent.expired ? (
                            <ModalFooter>
                                <Button className='btn-info' onClick={this.onEditClick}>Edit</Button>
                                <Button className='btn-primary' onClick={() => this.setState({ showselectedevent: false })}>Close</Button>
                            </ModalFooter>
                        ) : ""}

                    {
                        // Recruit or Not User
                        this.state.user && this.state.role == 'Recruit' || this.state.user == null ? (
                            <ModalFooter>
                                <Button className='btn-primary' onClick={() => this.setState({ showselectedevent: false })}>Close</Button>
                            </ModalFooter>
                        ) : ""}
                    {
                        // Admin User
                        this.state.user && this.state.role == 'Administrator' ? (
                            <ModalFooter>Admin links
                                <Button className='btn-warning' onClick={this.onDeleteClick}>Cancel WP</Button>
                                <Button className='btn-info' onClick={this.onEditClick}>Edit</Button>
                            </ModalFooter>
                        ) : ""}

                </Modal>
                <Modal isOpen={this.state.showEmailModal} toggle={this.toggleEmailModal} >
                    <ModalHeader>
                        Send Email to participants of &quot;
                        {this.state.selectedevent == null ? "" : this.state.selectedevent.title}
                        &quot;
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="4"><b>Subject</b></Col>
                            <Col xs="8"></Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Input type="text" name="emailsubject" id="emailSubject"
                                    onChange={this.onEmailFormChange}
                                    invalid={this.state.emailsubject == null || this.state.emailsubject.trim() === ""}
                                    defaultValue={ this.state.emailsubject  }
                                     />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="4"><b>Message</b></Col>
                            <Col xs="8"></Col>
                        </Row>
                        <Row>
                            <Col xs="4"><b>Path</b></Col>
                            <Col xs="8">{this.state.selectedevent.path == null ? "" : this.state.selectedevent.path}</Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Input type="textarea" name="emailtext" id="emailText"
                                    onChange={this.onEmailFormChange}
                                    invalid={this.state.emailtext.trim() === ""}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-info' onClick={this.onSendEmail}>Send</Button>
                        <Button className='btn-primary' onClick={() => this.setState({ showEmailModal: false })}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export const WorkProgramCalendar = withTranslation()(WorkProgramCalendarPlain);
