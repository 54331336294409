import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import { WorkProgramCalendar } from '../workprogramcalendar/WorkProgramCalendar';
import { WorkProgramList } from './WorkProgramList';
import { WorkProgramAdd } from './WorkProgramAdd';
import { WorkProgramDelete } from './WorkProgramDelete';
import { WorkProgramEdit } from './WorkProgramEdit';

export function WorkProgramRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}/calendar`} component={WorkProgramCalendar} />
			<AuthorizeRoute path={`${match.path}/add`} component={WorkProgramAdd} />
			<Route path={`${match.path}/delete/:id`} component={WorkProgramDelete} />
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={WorkProgramEdit} />
			<AuthorizeRoute path={`${match.path}`} component={WorkProgramList} />
		</Switch>
	);
}