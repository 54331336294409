import React from "react";
import { withLeaflet, CircleMarker } from "react-leaflet";
import Control from "react-leaflet-control";
import { FaLocationArrow } from "react-icons/fa";
import { Button } from "reactstrap";
import L from "leaflet";
//import { withNamespaces } from "react-i18next";

class GeoLocation extends React.Component {
    constructor(props) {
        super(props);

        this.updateLocation = this.updateLocation.bind(this);
        this.locate = this.locate.bind(this);
        this.toggleState = this.toggleState.bind(this);

        this.state = {
            enabled: false,
            location: null,
            watchId: null
        };
    }

    componentDidMount() {
        this.forceUpdate();
    }

    toggleState() {
        if (!navigator.geolocation) {
            alert("GeoLocation not supported on your device");
            return;
        }

        if (!this.state.enabled) {
            this.locate();
        } else {
            this.stop();
        }
    }

    stop() {
        navigator.geolocation.clearWatch(this.watchId);
        this.setState({ enabled: false, watchId: null, location: null });
    }

    locate() {
        let geoOptions = {
            enableHighAccuracy: true
        };

        this.watchId = navigator.geolocation.watchPosition(
            this.updateLocation,
            function (e) {
                console.log(e);
            },
            geoOptions
        );
    }

    updateLocation(position) {
        if (!this.withinParkBoundary(position)) {
            alert("You must be within the National Park to use your location.");
            this.stop();
        } else {
            this.setState({ location: position, enabled: true });
        }
    }

    withinParkBoundary(position) {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        return lng > -4 && lng < -2.9 && lat < 52.08 && lat > 51.5;
    }

    render() {
        let enabledStateStyle = "secondary";
        let enabledStateIconStyle = "grey";
        if (this.state.enabled) {
            enabledStateStyle = "primary";
            enabledStateIconStyle = "#007bff";
        }

        let locationMarker = null;
        if (this.state.location) {
            const latlng = L.latLng(
                this.state.location.coords.latitude,
                this.state.location.coords.longitude
            );
            locationMarker = (
                <React.Fragment>
                    <CircleMarker
                        radius={this.state.location.coords.accuracy}
                        center={latlng}
                        stroke={false}
                        fillOpacity={0.1}
                    />
                    <CircleMarker
                        radius={10}
                        center={latlng}
                        color="#ffffff"
                        fillColor="#007bff"
                        fillOpacity={0.9}
                    />
                </React.Fragment>
            );
            this.props.leaflet.map.setView(latlng, 6);
        }
        //const t = this.props.t;
        let t = (s) => s;
        return (
            <React.Fragment>
                <Control position="bottomright">
                    <Button
                        title={t("show-location")}
                        className={"btn-circle btn-lg"}
                        style={{ backgroundColor: "white", color: "grey" }}
                        color={enabledStateStyle}
                        onClick={this.toggleState}
                    >
                        <FaLocationArrow color={enabledStateIconStyle} size="1.5em" />
                    </Button>
                </Control>
                {locationMarker}
            </React.Fragment>
        );
    }
}

//export default withNamespaces()(withLeaflet(GeoLocation));
export default withLeaflet(GeoLocation);
