import React, { Component } from 'react';
import Select, { components } from 'react-select';
export const VolunteerGroupOption = props => {
    return (
        <div>
            <components.Option {...props} ><span style={{ backgroundColor: props.data.colour }} className="volunteer-group-dropdown-colourbox"></span>{props.label}</components.Option>
        </div>
    );
};

export const VolunteerGroupValueContainer = ({ children, ...props }) => {
    console.log(props);
    console.log(props.getValue());
    /*<components.ValueContainer {...props}><span style={{ backgroundColor: props.data.colour }} className="volunteer-group-dropdown-colourbox"></span>{props.label}{children}</components.ValueContainer>*/
    if (props.hasValue) {
        return (
            <components.ValueContainer {...props}><div><span style={{ backgroundColor: props.getValue()[0].colour }} className="volunteer-group-dropdown-colourbox"></span>{props.getValue()[0].label}</div></components.ValueContainer>
        );
    }
    else {
        return (
            <components.ValueContainer {...props}>{children}</components.ValueContainer>
        );
    }
};