import React, { Component } from 'react';
import pathService from './PathService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { HuePicker, SketchPicker } from 'react-color';

class PathAddPlain extends Component {

	constructor(props) {
		super(props);
		this.state = {
			
		};
	}

	componentDidMount() {
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/paths');
	}

	handleValidSubmit = (event, values) => {
		const { history } = this.props;
		let postvalues = values;
		
		(async () => {
			await pathService.add(postvalues);
			history.push('/paths');
		})();
	}
	
	render() {
		const { t, i18n } = this.props;
		return (
			<AvForm onValidSubmit={this.handleValidSubmit}>
				<AvField name="name" label={t('Name')} required errorMessage={t('FieldInvalid')} validate={{
					required: { value: true, errorMessage: t('FieldRequired') },
					minLength: { value: 2 }
				}} />
				<AvField name="surveyIntervalMonths" label={t('SurveyIntervalMonths')} type="number" />
				<AvField name="lengthKilometres" label={t('LengthKilometres')} type="number" step='0.01' />
				<FormGroup>
					<Button>Save</Button>&nbsp;
					<Button onClick={this.handleClickCancel}>Cancel</Button>
				</FormGroup>
			</AvForm>
		);
	}
}

export const PathAdd = withTranslation()(PathAddPlain);
