import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import userService from './UserService';
import { Pager } from '../Pager';
import { FormGroup, Form, Input, Button, Table } from 'reactstrap';
import { FaKey, FaSortAlphaDown, FaSortAlphaUp, FaEdit, FaTrash } from 'react-icons/fa';

export class UserActivate extends Component {
    constructor(props) {
        super(props);
        this.state = { users: [], loading: true, selecteduser: this.props.match.params.userId ? props.userId : null };
        
    }

    componentDidMount() {
        this.populateUserData();
    }
    async populateUserData() {
        try {

            const data = await userService.getRecruits();
            this.setState({ users: data.result, loading: false });
        }
        catch (error) {
            // Handle error
        }
    }
    async activateUser(id) {
        await userService.activateRecruit(id);
    }
    onActivateClick = (id) => {
        this.activateUser(id);
        this.populateUserData();
    }
    renderUsersTable() {
        const { users, selecteduser } = this.state;
        const filteredusers = users.filter(o => this.props.match.params.userId ? o.id == this.props.match.params.userId : true);
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Activate</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredusers.length == 0 && users.length == 0 ? (
                        <tr><td colSpan="3">No users to activate</td></tr>
                    ) : ""}
                    {filteredusers.length == 0 && users.length > 0 ? (
                        <tr><td colSpan="3"><Link to={'/users/activate'}>List is filtered click here show results</Link></td></tr>
                    ): ""}

                    {filteredusers.map(user =>
                        <tr key={user.id}>
                            <td>{user.firstname + ' ' + user.surname}</td>
                            <td>{user.email}</td>
                            <td><Button onClick={() => this.onActivateClick(user.id)} color="primary">Activate</Button></td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderUsersTable();

        return (
            <div>
                <h1 id="tableLabel">User Activation</h1>
                { this.props.match.params.userId ? (
                    <Link to={'/users/activate' }>List is filtered to a one recruit, click to remove filter</Link>
                ): "" }
                {contents}
            </div>
        );
    }
}