import React, { Component } from "react";
import { wgs84, osgb } from "../osgb";
import PathMap from "./PathMap";
import { withTranslation } from 'react-i18next';
import pathService from '../paths/PathService';
import workProgramService from '../workprograms/WorkProgramService';
import {
    FormGroup, Form, Label, Input, Button, Table,
    Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, ButtonGroup,
    InputGroup
} from 'reactstrap';

class PathMapPage extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            selectedfeature: null,
            workprograms: []
        };
        this.toggleModal = this.toggleModal.bind(this);
    }
    onClickHandler = (event, features) => {
        this.wgs84.setDegrees(event.latlng.lat, event.latlng.lng);

        let osgbref = this.wgs84.getOSGB();

        this.setState({ location: { lat: this.wgs84.latitude, lng: this.wgs84.longitude }, gridref: osgbref.getGridRef(6) });

        if (features.numberReturned > 0) {
            this.getPathData(features.features[0].properties.path_name);
        } else {
            this.setState({ showModal: false });
        }
    }
    async getPathData(name) {
        try {
            const data = await pathService.getbyname(name);
            this.setState({ path: data.result, showModal: true });
            this.getWorkPrograms(data.result.id);
        }
        catch (error) {
            // Handle error
        }
    }
    async getWorkPrograms(pathid) {
        try {
            const data = await workProgramService.bypathid(pathid);
            this.setState({ workprograms: data });
        }
        catch (error) {
            // Handle error
        }
    }
    toggleModal(e) {
        this.setState({
            showModal: !this.state.showModal
        });
    }
    handleCancel = () => {
        this.setState({ showModal: false, location: null, gridref: '', feature: null, path: null, workprograms: [] });
    }
    render() {
        return (
            <div style={{ position: "absolute", left: "0", width: "100%", height: "calc(100% - 260px)" }}>
                <PathMap onClick={this.onClickHandler} selectedLocation={this.state.location}>
                </PathMap>
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal} size="lg" scrollable>
                    <ModalHeader>
                        {this.state.path ? this.state.path.name : ''}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="4"><b>Gridref</b></Col>
                            <Col xs="8">{this.state.gridref}</Col>
                        </Row>
                        {this.state.location ?
                            (<Row>
                                <Col xs="4"><b>Latitude</b></Col>
                                <Col xs="8">{this.state.location.lat.toFixed(5)}</Col>
                            </Row>) : null}
                        {this.state.location ?
                            (<Row>
                                <Col xs="4"><b>Longitude</b></Col>
                                <Col xs="8">{this.state.location.lng.toFixed(5)}</Col>
                            </Row>) : null}
                        {this.state.path ?
                            (<Row>
                                <Col xs="4"><b>Path</b></Col>
                                <Col xs="8">{this.state.path.name}</Col>
                            </Row>) : null}
                        {this.state.path ?
                            (<Row>
                                <Col xs="4"><b>Path Length</b></Col>
                                <Col xs="8">{this.state.path.lengthKilometres} km</Col>
                            </Row>) : null}
                        {this.state.path ?
                            (<Row>
                                <Col xs="4"><b>Survey Interval</b></Col>
                                <Col xs="8">{this.state.path.surveyIntervalMonths} Months</Col>
                            </Row>) : null}
                        {this.state.workprograms.length > 0 ? (
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Comment</th>
                                        <th>Condition</th>
                                        <th>Work Required</th>
                                        <th>Time Spent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.workprograms.map(wp =>
                                        <tr key={wp.id}>
                                            <td>{new Date(wp.start.substr(0, 10)).toLocaleDateString()}</td>
                                            <td>{wp.surveyComment}</td>
                                            <td>{wp.pathState ? wp.pathState.state : ''}</td>
                                            <td>{wp.workRequired ? wp.workRequired.state : ''}</td>
                                            <td>{wp.timeSpentHours} hours</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        ) : (
                            <Row>
                                <Col xs="12"><b>No surveys/work programs found</b></Col>
                            </Row>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-primary' onClick={this.handleCancel}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default withTranslation('en')(PathMapPage);