import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants'

export class WorkProgramService  {
	async getData(page, pageSize, sortOrder, searchString) {
		const token = await authService.getAccessToken();
		const offset = (page - 1) * pageSize;

		// Taken from here: https://fetch.spec.whatwg.org/#fetch-api
		let url = "workprogram?" + "offset=" + offset + "&limit=" + pageSize + "&sortOrder=" + sortOrder;
		if (searchString !== "")
			url += "&searchString=" + encodeURIComponent(searchString);

		try {
			const response = await fetch(url, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				if (response.status == 401) { // Refresh access token if it is expired
					window.location.href =
						`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				}

				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async delete(entityId) {
		const token = await authService.getAccessToken();

		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('workprogram/' +entityId, {
				method: 'DELETE',
				headers: headers,
			});

			if (response.ok) {
				return;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async get(entityId) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('workprogram/' + entityId, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async add(entity) {
		const token = await authService.getAccessToken();

		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('workprogram', {
				method: 'POST',
				body: JSON.stringify(entity),
				headers: headers,
			});

			if (response.ok) {
				return;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}

	}

	async update(id, entity) {
		const token = await authService.getAccessToken();

		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('workprogram/' + id, {
				method: 'PUT',
				body: JSON.stringify(entity),
				headers: headers,
			});

			if (response.ok) {
				return;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async calendareventlist(start, end, group) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('workprogram/calendarevents?start=' + start + '&end=' + end+ '&offset=0', {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}
	// not authenticated or recruit
	async calendareventlistlimited(start, end, group) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('workprogram/calendareventslimited?start=' + start + '&end=' + end + '&offset=0', {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}
	//
	async joinWorkProgram(workProgramId, userId) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('workprogram/joinworkprogram?workprogramid=' + workProgramId + '&userid=' + userId, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}
	async leaveWorkProgram(workProgramId, userId) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('workprogram/leaveworkprogram?workprogramid=' + workProgramId + '&userid=' + userId, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}
	async emailParticipants(workProgramId, userId, emailSubject,emailText) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('workprogram/emailparticipants', {
				method: 'POST',
				body: JSON.stringify({ workProgramId, userId, emailSubject, emailText }),
				headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async bypathid(pathid) {
		try {
			const response = await fetch('workprogram/path/' + pathid, {
				headers: {}
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
    }

	static get instance() { return workProgramService; } // TODO: Check singleton/lifetime
}

const workProgramService = new WorkProgramService();

export default workProgramService;