import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { UserRouter } from './components/users/UserRouter';
import { WorkProgramRouter } from './components/workprograms/WorkProgramRouter';
import { VolunteerGroupRouter } from './components/volunteergroups/VolunteerGroupRouter';
import { VolunteerSectionRouter } from './components/volunteersections/VolunteerSectionRouter';
import { PathRouter } from './components/paths/PathRouter';
import { PathWorkRouter } from './components/pathworks/PathWorkRouter';
import { PathStateRouter } from './components/pathstates/PathStateRouter';
import { ReportedIssueRouter } from './components/reportedissues/ReportedIssueRouter';
import { WorkProgramCalendar } from './components/workprogramcalendar/WorkProgramCalendar';
import PathMapPage from './components/pathmap/PathMapPage'
import ReportIssueMapPage from './components/pathmap/ReportIssueMapPage'
import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                {/*<Route path='/pathmap' component={Counter} />*/}
                {/*<AuthorizeRoute path='/workprogramcalendar' component={WorkProgramCalendar} />*/}
                <Route path='/publicworkprogramcalendar' component={WorkProgramCalendar} />
                <AuthorizeRoute path='/users' component={UserRouter} />
                <AuthorizeRoute path='/workprograms' component={WorkProgramRouter} />
                <Route path='/pathmap' render={routeProps => <PathMapPage {...routeProps} {...this.props} />} />
                <Route path='/reportissuemap' render={routeProps => <ReportIssueMapPage {...routeProps} {...this.props} />} />
                <AuthorizeRoute path='/volunteergroups' component={VolunteerGroupRouter} />
                <AuthorizeRoute path='/volunteersections' component={VolunteerSectionRouter} />
                <AuthorizeRoute path='/paths' component={PathRouter} />
                <AuthorizeRoute path='/pathstates' component={PathStateRouter} />
                <AuthorizeRoute path='/pathworks' component={PathWorkRouter} />
                <AuthorizeRoute path='/reportedissues' component={ReportedIssueRouter} />

                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
