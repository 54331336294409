import React, { Component } from 'react';
import { withRouter } from 'react-router';
import volunteerSectionService from './VolunteerSectionService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { HuePicker, SketchPicker } from 'react-color';

class VolunteerSectionEditPlain extends Component {
	constructor(props) {
		super(props);
		this.state = { entity: null, loading: true };

		const { match } = this.props;
		this.id = match.params.id;
	}

	componentDidMount() {
		this.retrieveFormData();
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.state.entity[name] = value;
		this.setState({ entity: this.state.entity});
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/volunteersections');
	}

	handleValidSubmit = (event, values) => {
		const { history } = this.props;
		let processedValues = values;
		
		(async () => {
			await volunteerSectionService.update(this.id, processedValues);
			history.push('/volunteersections');
		})();
	}

	renderForm(entity) {
		const { t, i18n } = this.props;
		return (
			<AvForm model={entity} onValidSubmit={this.handleValidSubmit}>
				<AvField name="id" type="hidden" />
				<AvField name="rowVersion" type="hidden" />
				<AvField name="name" label={t('Name')} required errorMessage={t('FieldInvalid')} validate={{
					required: { value: true, errorMessage: t('FieldRequired') },
					minLength: { value: 2}
				}} />
				<p></p>
				<FormGroup>
					<Button>Save</Button>&nbsp;
					<Button onClick={this.handleClickCancel}>Cancel</Button>
				</FormGroup>
			</AvForm>
		);
	}

	render() {
		let contents = this.state.loading
			? <p><em>Loading...</em></p>
			: this.renderForm(this.state.entity);

		return (
			<div>
				<h1 id="tabelLabel">Volunteer Section</h1>
				{contents}
			</div>
		);
	}

	async retrieveFormData() {
		const data = await volunteerSectionService.get(this.id);
		this.setState({ entity: data, loading: false });
	}
}

export const VolunteerSectionEdit = withTranslation()(withRouter(VolunteerSectionEditPlain));