import React, { Component } from "react";
import { wgs84, osgb } from "../osgb";
import PathMap from "./PathMap";
import { withTranslation } from 'react-i18next';
import pathService from '../paths/PathService';
import {
    FormGroup, Form, Label, Input, Button,
    Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, ButtonGroup,
    InputGroup
} from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import reportedIssueService from "../reportedissues/ReportedIssueService";

class ReportIssueMapPage extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            feature: null,
            path: null,
            location: null,
            gridref: '',
            showModal: false,
            showSentModal: false,
            fileupload: null
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleSentModal = this.toggleSentModal.bind(this);
    }
    componentDidMount() {
        //this.populateUserState();
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }
    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const user = await authService.getUser();
        this.setState({ ready: true, isAuthenticated: authenticated, user, role: user && user.role, firstname: user && user.firstname });
        this.userStateReturned();
    }
    userStateReturned() {
        //(async () => {
        //    await workProgramService.add(postvalues);
        //    //history.push('/workprograms');
        //    history.goBack();
        //})();
    }
    onClickHandler = (event, features) => {
        this.wgs84.setDegrees(event.latlng.lat, event.latlng.lng);

        let osgbref = this.wgs84.getOSGB();

        this.setState({ location: { lat: this.wgs84.latitude, lng: this.wgs84.longitude }, gridref: osgbref.getGridRef(6) });

        if (features.numberReturned > 0) {
            this.getPathData(features.features[0].properties.path_name);
        } else {
            this.setState({ showModal: true });
        }
    }
    async getPathData(name) {
        try {
            const data = await pathService.getbyname(name);
            this.setState({ path: data.result, showModal: true });
        }
        catch (error) {
            // Handle error
        }
    }
    toggleModal(e) {
        this.setState({
            showModal: !this.state.showModal
        });
    }
    toggleSentModal(e) {
        this.setState({
            showSentModal: !this.state.showModal
        });
    }
    handleFileChange = (e) => {
        this.setState({ file: e.target.files[0] });
    }
    handleCancel = () => {
        this.setState({ showModal: false, location: null, gridref: '', feature: null, path: null });
    }
    handleSend = () => {
        let valid = true;
        let reportedissue = {};
        if (this.state.email != "" || this.state.user) {
            if (this.state.user) {
                reportedissue.volunteer = { id: this.state.user.sub }
            } else {
                reportedissue.email = this.state.email;
            }
        } else { valid = false; }
        if (this.state.comment) {
            reportedissue.comment = this.state.comment;
        } else { valid = false; }
        if (this.state.path) {
            reportedissue.path = { id: this.state.path.id }
        }
        reportedissue.gridref = this.state.gridref;
        reportedissue.longitude = this.state.location.lng;
        reportedissue.latitude = this.state.location.lat;
        if (valid == true) {
            this.postEntity(reportedissue);
        }
    }
    async postEntity(entity) {
        let saveresponse = await reportedIssueService.add(entity);
        console.log(saveresponse.id);
        console.log(this.state.file);
        if (this.state.file) {
            const formdata = new FormData();
            formdata.append('file', this.state.file);
            formdata.append('id', saveresponse.id);
            fetch("reportedissue/postimage", {
                method: "post",
                /*headers: { 'Content-Type': 'multipart/form-data' },*/
                body: formdata
            })
                .then(response => response)
                .then(data => {
                    console.log(data);
                    this.setState({ showModal: false, location: null, gridref: '', feature: null, path: null, showSentModal:true });
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            this.setState({ showModal: false, location: null, gridref: '', feature: null, path: null, showSentModal: true });
        }
    }
    handleFormUpdate = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        return (
            <div style={{ position: "absolute", left: "0", width: "100%", height: "calc(100% - 260px)" }}>
                <PathMap onClick={this.onClickHandler} selectedLocation={this.state.location}>
                </PathMap>
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal} >
                    <ModalHeader>
                        Report Issue
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="4"><b>Gridref</b></Col>
                            <Col xs="8">{this.state.gridref}</Col>
                        </Row>
                        {this.state.location ?
                            (<Row>
                                <Col xs="4"><b>Latitude</b></Col>
                                <Col xs="8">{this.state.location.lat}</Col>
                            </Row>) : null}
                        {this.state.location ?
                            (<Row>
                                <Col xs="4"><b>Longitude</b></Col>
                                <Col xs="8">{this.state.location.lng}</Col>
                            </Row>) : null}
                        {this.state.path ?
                            (<Row>
                                <Col xs="4"><b>Path</b></Col>
                                <Col xs="8">{this.state.path.name}</Col>
                            </Row>) : null}
                        {this.state.user ?
                            (
                                <Row>
                                    <Col xs="4"><b>Reported by</b></Col>
                                    <Col xs="8">{this.state.user.name}</Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col xs="4"><b>Email</b></Col>
                                    <Col xs="8"><Input type="email" name="email" onChange={this.handleFormUpdate} /></Col>
                                </Row>
                            )}
                        <Row>
                            <Col xs="4"><b>Issue Details</b></Col>
                            <Col xs="8"><Input type="textarea" name="comment" onChange={this.handleFormUpdate} /></Col>
                        </Row>
                        <Row>
                            <Col xs="4"><b>Photo</b></Col>
                            <Col xs="8"><Input type="file" accept="image/*" name="upload" onChange={(e) => this.handleFileChange(e)} /></Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-success' onClick={this.handleSend}>Send</Button>
                        <Button className='btn-primary' onClick={this.handleCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.showSentModal} toggle={this.toggleSentModal} >
                    <ModalHeader>
                        Issue Submitted
                    </ModalHeader>
                    <ModalFooter>
                        <Button className='btn-primary' onClick={() => this.setState({ showSentModal: false })}>Close</Button>
                    </ModalFooter>
                    </Modal>
            </div>
        );
    }
}
export default withTranslation('en')(ReportIssueMapPage);