import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';

import { PathWorkList } from './PathWorkList';
import { PathWorkAdd } from './PathWorkAdd';
import { PathWorkDelete } from './PathWorkDelete';
import { PathWorkEdit } from './PathWorkEdit';

export function PathWorkRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}/add`} component={PathWorkAdd} />
			<Route path={`${match.path}/delete/:id`} component={PathWorkDelete} />
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={PathWorkEdit} />
			<AuthorizeRoute path={`${match.path}`} component={PathWorkList} />
		</Switch>
	);
}