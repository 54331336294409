import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';

import { VolunteerGroupList } from './VolunteerGroupList';
import { VolunteerGroupAdd } from './VolunteerGroupAdd';
import { VolunteerGroupDelete } from './VolunteerGroupDelete';
import { VolunteerGroupEdit } from './VolunteerGroupEdit';

export function VolunteerGroupRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}/add`} component={VolunteerGroupAdd} />
			<Route path={`${match.path}/delete/:id`} component={VolunteerGroupDelete} />
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={VolunteerGroupEdit} />
			<AuthorizeRoute path={`${match.path}`} component={VolunteerGroupList} />
		</Switch>
	);
}