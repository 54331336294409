import React, { Component } from 'react';
import { withRouter } from 'react-router';
import workProgramService from './WorkProgramService';
import { Button } from 'reactstrap';

class WorkProgramDeletePlain extends Component {
	constructor(props) {
		super(props);
		this.state = { entities: [], loading: true };

		const { match } = this.props;
		this.id = match.params.id;
	}

	componentDidMount() {

	}

	handleClickOk = () => {
		const { history } = this.props;

		(async () => {
			await workProgramService.delete(this.id);
			//history.push('/workprograms');
			history.goBack();
		})();

		//usersService.deleteUser(this.userId)
		//	.then(() => history.push('/users'));
	}

	handleClickCancel = () => {
		const { history } = this.props;
		//history.push('/workprograms');
		history.goBack();
		
	}

	render() {
		return (
			<div>
				<h2>Are you sure you want to cancel and delete this work program ?</h2>

				<button onClick={this.handleClickOk}>Yes</button>
				<button onClick={this.handleClickCancel}>No</button>
			</div>
		);
	}
}
export const WorkProgramDelete = withRouter(WorkProgramDeletePlain);