import React, { Component } from "react";
import { wgs84, osgb } from "../osgb";
import PathMap from "./PathMap";
import { withTranslation } from 'react-i18next';
import pathService from '../paths/PathService';

class GridrefSelector extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            selectedfeature: null,
            selectedgridref: null,
            selectedlocation: null
        };
    }
    onClickHandler = (event, features) => {
        this.wgs84.setDegrees(event.latlng.lat, event.latlng.lng);
        //convert to OSGB
        let osgbref = this.wgs84.getOSGB();
        //this.osgb.parseGridRef(osgbref.getGridRef(6));
        // let wgs84ref = this.osgb.getWGS84();
       
        if (this.props.onChange) {
            this.props.onChange(osgbref.getGridRef(6));
        }
        if (features.numberReturned > 0 && this.props.onChangePath) {
            this.getPathData(features.features[0].properties.path_name);
        }
    }
    async getPathData(name) {
        try {
            const data = await pathService.getbyname(name);
            this.props.onChangePath(data.result);
        }
        catch (error) {
            console.log(error);
            // Handle error
        }
    }
    render() {
        let wgs84ref = null;
        let invalid = false;
        try {
            if (this.osgb.parseGridRef(this.props.value)) {
                wgs84ref = this.osgb.getWGS84();
            } else {
                invalid = true;
            }
        } catch (e) {
            invalid = true;
        }
        if (invalid && this.props.hideInvalid) {
            return (<div></div>);
        }
        else {
            return (
                <div style={{ width: "100%", height: "400px" }}>
                    <PathMap onClick={this.onClickHandler} selectedLocation={wgs84ref ? { lat: wgs84ref.latitude, lng: wgs84ref.longitude } : null} isStatic={this.props.isStatic} selectZoom={1}>
                    </PathMap>
                </div>
            );
        }
        
    }
}
export default withTranslation('en')(GridrefSelector);