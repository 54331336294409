import React, { Component } from 'react';
import { withRouter } from 'react-router';
import workProgramService from './WorkProgramService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { SelectFetch } from 'react-select-fetch';
import { AsyncPaginate } from 'react-select-async-paginate';
import userService from '../users/UserService';
import pathService from '../paths/PathService';
import pathStateService from '../pathstates/PathStateService';
import pathWorkService from '../pathworks/PathWorkService';
import volunteerGroupService from '../volunteergroups/VolunteerGroupService';
import { VolunteerGroupOption, VolunteerGroupValueContainer } from '../volunteergroups/VolunteerGroupSelect'
import GridrefSelector from '../pathmap/GridrefSelector';

class WorkProgramEditPlain extends Component {
    constructor(props) {
        super(props);
        this.state = { entity: null, loading: true };

        const { match } = this.props;
        this.id = match.params.id;
    }

    handleLeaderSelectChange(e) {
        this.state.entity["leadervalue"] = { value: e.value, label: e.label };
        this.setState({ entity: this.state.entity });
    }

    handleGoingSelectChange(e) {
        this.state.entity["goingvalue"] = e;
        this.setState({ entity: this.state.entity });
    }

    handlePathSelectChange(e) {
        this.state.entity["pathvalue"] = { value: e.value, label: e.label };
        this.setState({ entity: this.state.entity });
    }
    handlePathMapChange = (path) => {
        this.state.entity["pathvalue"] = { value: path.id, label: path.name };
        this.setState({ entity: this.state.entity });
    }
    handlePathStateSelectChange(e) {
        this.state.entity["pathstatevalue"] = { value: e.value, label: e.label };
        this.setState({ entity: this.state.entity });
    }

    handleWorkRequiredSelectChange(e) {
        this.state.entity["workrequiredvalue"] = { value: e.value, label: e.label };
        this.setState({ entity: this.state.entity });
    }
    handleVolunteerGroupSelectChange(e) {
        this.state.entity["volunteergroupvalue"] = { value: e.value, label: e.label, colour: e.colour };
        this.setState({ entity: this.state.entity });
    }

    async getSelectOptions() {

    }

    componentDidMount() {
        this.retrieveFormData();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.state.entity[name] = value;
        this.setState({ entity: this.state.entity });
    }

    handleClickCancel = () => {
        const { history } = this.props;

        //history.push('/workprograms');
        history.goBack();
    }

    handleGridrefChange = (e) => {
        this.state.entity["gridref"] = e.target.value;
        this.setState({ entity: this.state.entity });
    }
    handleGridrefMapChange = (newgridref) => {
        this.state.entity["gridref"] = newgridref;
        this.setState({ entity: this.state.entity });
    }
    handleValidSubmit = (event, values) => {
        const { history } = this.props;
        let postvalues = values;

        /*
         * goingvalue
         * leadervalue
         * workrequiredvalue
         * pathvalue
         * pathstatevalue
         * volunteergroupvalue
        */
        let going = [];
        for (let person of this.state.entity["goingvalue"]) {
            going.push({ id: person.value });
        }
        postvalues["going"] = going;

        if (this.state.entity["leadervalue"]) {
            postvalues["leader"] = { id: this.state.entity["leadervalue"].value };
        }
        if (this.state.entity["pathvalue"]) {
            postvalues["path"] = { id: this.state.entity["pathvalue"].value };
        }
        if (this.state.entity["pathstatevalue"]) {
            postvalues["pathState"] = { id: this.state.entity["pathstatevalue"].value };
        }
        if (this.state.entity["workrequiredvalue"]) {
            postvalues["workRequired"] = { id: this.state.entity["workrequiredvalue"].value };
        }
        if (this.state.entity["volunteergroupvalue"]) {
            postvalues["volunteerGroup"] = { id: this.state.entity["volunteergroupvalue"].value };
        }
        if (this.state.entity["gridref"]) {
            postvalues["gridref"] = this.state.entity["gridref"];
        }
        if (postvalues.timeSpenHours == "" || isNaN(postvalues.timeSpenHours)) {
            postvalues.timeSpenHours = 0;
        } else {
            postvalues.timeSpenHours = Math.ceil(postvalues.timeSpenHours);
        }
        (async () => {
            await workProgramService.update(this.id, postvalues);
            //history.push('/workprograms');
            history.goBack();
        })();
    }

    renderForm(entity) {
        const { t, i18n } = this.props;
        return (
            <AvForm model={entity} onValidSubmit={this.handleValidSubmit}>
                <AvField name="id" type="hidden" />
                <AvField name="rowVersion" type="hidden" />
                <AvField name="title" label={t('Title')} required errorMessage={t('FieldInvalid')} validate={{
                    required: { value: true, errorMessage: t('FieldRequired') },
                    minLength: { value: 2 }
                }} />
                <FormGroup>
                    <Label id="leader-label" htmlFor="leader">
                        {t('Leader')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.entity.leadervalue}
                        loadOptions={async (search, loadedLeaderOptions, { page }) => {
                            const responseJSON = await userService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handleLeaderSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                    />

                    {/*<SelectFetch*/}
                    {/*    name="leader"*/}
                    {/*    aria-labelledby="leader-label"*/}
                    {/*    inputId="leader"*/}
                    {/*    value={this.state.entity.leadervalue}*/}
                    {/*    url='/users/selectlist'*/}
                    {/*    mapResponse={(response) => ({*/}
                    {/*        options: response.result,*/}
                    {/*        hasMore: response.hasmore*/}
                    {/*    })}*/}
                    {/*    //options={this.state.selectOptions}*/}
                    {/*    onChange={this.handleLeaderSelectChange.bind(this)}*/}
                    {/*/>*/}
                </FormGroup>
                <FormGroup>
                    <Label id="volunteerGroup-label" htmlFor="volunteerGroup">
                        {t('VolunteerGroup')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.entity.volunteergroupvalue}
                        loadOptions={async (search, loadedVolunteerGroupOptions, { page }) => {
                            const responseJSON = await volunteerGroupService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handleVolunteerGroupSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                        components={{
                            Option: VolunteerGroupOption,
                            ValueContainer: VolunteerGroupValueContainer
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label id="going-label" htmlFor="going">
                        {t('Going')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.entity.goingvalue}
                        loadOptions={async (search, loadedGoingOptions, { page }) => {
                            const responseJSON = await userService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handleGoingSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                        isMulti='true'
                    />
                </FormGroup>

                <FormGroup>
                    <Label id="path-label" htmlFor="path">
                        {t('Path')} (this will update if a path is selected on map)
                    </Label>
                    <AsyncPaginate
                        value={this.state.entity.pathvalue}
                        loadOptions={async (search, loadedPathOptions, { page }) => {
                            const responseJSON = await pathService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handlePathSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                    />
                </FormGroup>

                <FormGroup>
                    <Label id="workrequired-label" htmlFor="workrequired">
                        {t('WorkRequired')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.entity.workrequiredvalue}
                        loadOptions={async (search, loadedPathOptions, { page }) => {
                            const responseJSON = await pathWorkService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handleWorkRequiredSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                    />
                </FormGroup>

                <AvField name="start" label={t('StartDate')} type="date" required />
                <AvField name="end" label={t('EndDate')} type="date" required />

                {/*<AvField name="gridref" label={t('Gridref')} />*/}
                <FormGroup>
                    <Label id="gridref-label" htmlFor="gridref">
                        {t('Gridref')} (this will update if a point is selected on map)
                    </Label>
                    <Input type="text" name="gridref" value={this.state.entity.gridref} onChange={(e) => this.handleGridrefChange(e) } />
                </FormGroup>
                <GridrefSelector onChange={this.handleGridrefMapChange} onChangePath={this.handlePathMapChange} value={this.state.entity.gridref} />
                <AvField name="location" label={t('Location')} />
                <AvField name="information" label={t('Information')} type="textarea" />

                <FormGroup>
                    <Label id="pathstate-label" htmlFor="pathstate">
                        {t('PathState')}
                    </Label>
                    <AsyncPaginate
                        value={this.state.entity.pathstatevalue}
                        loadOptions={async (search, loadedPathStateOptions, { page }) => {
                            const responseJSON = await pathStateService.selectlist(search, page, 0);
                            return {
                                options: responseJSON.result,
                                hasMore: responseJSON.hasmore,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                        onChange={this.handlePathStateSelectChange.bind(this)}
                        additional={
                            { page: 1, }
                        }
                    />
                </FormGroup>

                <AvField name="surveyComment" label={t('Survey Comment')} type="textarea" />
                <AvField name="timeSpentHours" label={t('Time Spent (hours)')} type="number" />
                <FormGroup>
                    <Button>Save</Button>&nbsp;
                    <Button onClick={this.handleClickCancel}>Cancel</Button>
                </FormGroup>
            </AvForm>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForm(this.state.entity);

        return (
            <div>
                <h1 id="tabelLabel">Work Program</h1>
                {contents}
            </div>
        );
    }

    async retrieveFormData() {
        const data = await workProgramService.get(this.id);
        data.start = data.start != null && data.start.length > 0 ? data.start.split('T')[0] : "";
        data.end = data.end != null && data.end.length > 0 ? data.end.split('T')[0] : "";
        data.leadervalue = data.leader == null ? null : { "label": data.leader.firstname + " " + data.leader.surname, "value": data.leader.id }

        let going = [];
        for (let person of data.going) {
            going.push({ value: person.id, label: person.firstname + " " + person.surname });
        }
        data.goingvalue = going;
        if (data.pathState) {
            data.pathstatevalue = { label: data.pathState.state, value: data.pathState.id };
        }
        if (data.workRequired) {
            data.workrequiredvalue = { label: data.workRequired.state, value: data.workRequired.id };
        }
        if (data.path) {
            data.pathvalue = { label: data.path.name, value: data.path.id };
        }
        if (data.volunteerGroup) {
            data.volunteergroupvalue = { label: data.volunteerGroup.name, value: data.volunteerGroup.id, colour: data.volunteerGroup.colour };
        }
        this.setState({ entity: data, loading: false });
    }
}

export const WorkProgramEdit = withTranslation()(withRouter(WorkProgramEditPlain));