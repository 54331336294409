import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FaCalendar, FaCalendarAlt, FaMapMarkerAlt, FaPencilAlt, FaBook } from 'react-icons/fa';
import {
    Container, Row, Col,
    CardDeck, Card, CardImg, CardText, CardBody, CardFooter, CardHeader,
    CardTitle, CardSubtitle, Button, CardLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService'

export class HomePlain extends Component {
    static displayName = HomePlain.name;
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            role: null,
            user: null,
            firstname: null,
        };
    }
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }
    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }
    async populateState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
        ]);
        this.setState({
            isAuthenticated,
            role: user && user.role,
            user: user,
            firstname: user && user.firstname
        });
    }
    render() {
        return (
            <div >
                <CardDeck>

                    <Card>
                        <CardHeader><CardTitle tag="h4"><FaCalendarAlt /> <Link to={this.state.isAuthenticated ? '/workprograms/calendar' : '/publicworkprogramcalendar' }className="CardHeadLink"> Work Programs</Link></CardTitle></CardHeader>
                        <CardBody>
                            <CardText>
                                <h6>Here you can:</h6>
                                <ul>
                                    <li>Find our next meetup</li>
                                    <li>See who is going</li>
                                    <li>Manage new events</li>
                                    <li>Complete survey reports</li>
                                </ul>
                                {this.state.isAuthenticated ? (
                                    <Link className='CardBodyLink' to='/workprograms/calendar'>Open Work Program Calendar</Link>
                                ) : (
                                    <Link className='CardBodyLink' to='/publicworkprogramcalendar'>Open Work Program Calendar</Link>
                                )}

                            </CardText>
                        </CardBody>
                        <CardFooter>Certain actions are only available to registered volunteers and require certain privilages.</CardFooter>
                    </Card>

                    <Card>
                        <CardHeader><CardTitle tag="h4"><FaMapMarkerAlt /> <Link to='/pathmap' className="CardHeadLink"> Paths Map</Link></CardTitle></CardHeader>
                        <CardBody>
                            <h6>Here you can:</h6>
                            <ul>
                                <li>Explore our paths map</li>
                                <li>View previous surveys on paths</li>
                            </ul>
                            <Link className='CardBodyLink' to='/pathmap'>Open Paths Map</Link>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader><CardTitle tag="h4"><FaPencilAlt /> <Link to='/reportissuemap' className="CardHeadLink"> Report Issues</Link></CardTitle></CardHeader>
                        <CardBody>
                            <p>
                                Tell us about problems you have experienced while walking in the park.
                                </p>
                            <Link className='CardBodyLink' to='/reportissuemap'>Report An Issue</Link>
                        </CardBody>
                    </Card>


                    <Card style={{ minWidth:"50%" }}>
                        <CardHeader><CardTitle tag="h4"><FaBook /> <span className="CardHeadLink"> Handbook and Facebook</span></CardTitle></CardHeader>
                        <CardBody>
                            <p>Please find the online BBNPA volunteer e-handbook <a href="https://sites.google.com/view/gwirfoddolwyrawdurdodparccened/home">here (Welsh)</a> and <a href="https://sites.google.com/view/bbnpavolunteerinformation/home">here (English)</a>.
                            The handbook contains information such as policies, procedures and contacts that will be useful during your volunteering.</p>

                            <p>If you use social media, you may be interested in joining our closed volunteers Facebook group, which can be found <a href="https://www.facebook.com/groups/216724012181508/?multi_permalinks=1094628574391043&notif_id=1618185243644150&notif_t=group_activity&ref=notif">Here</a>.
                            The group is a friendly forum for BBNPA volunteers to share news and photos about what they've been up to during their volunteering.</p>
                        </CardBody>
                    </Card>


                </CardDeck>
            </div>
        );
    }
}
export const Home = withTranslation('en')(HomePlain);