import React, { Component } from 'react';
import volunteerGroupService from './VolunteerGroupService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { HuePicker, SketchPicker } from 'react-color';

class VolunteerGroupAddPlain extends Component {

	constructor(props) {
		super(props);
		this.state = {
			colour: null
		};
	}

	componentDidMount() {
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/volunteergroups');
	}

	handleValidSubmit = (event, values) => {
		const { history } = this.props;
		let postvalues = values;
		postvalues.colour = this.state.colour;
		(async () => {
			await volunteerGroupService.add(postvalues);
			history.push('/volunteergroups');
		})();
	}
	handleColourChange = (colour) => {
		this.state.colour = colour.hex;
		this.setState({ entity: this.state.colour });
	}
	handleColourTextChange = (value) => {
		console.log(value.target.value);
		this.state.colour = value.target.value;
		this.setState({ entity: this.state.colour });
	}
	render() {
		const { t, i18n } = this.props;
		return (
			<AvForm onValidSubmit={this.handleValidSubmit}>
				<AvField name="name" label={t('Name')} required errorMessage={t('FieldInvalid')} validate={{
					required: { value: true, errorMessage: t('FieldRequired') },
					minLength: { value: 2 }
				}} />
				<FormGroup>
					<Input type="text" name="colour" onChange={this.handleColourTextChange} value={this.state.colour} style={{ backgroundColor: this.state.colour }} />
				</FormGroup>
				<HuePicker color={this.state.colour == null ? '' : this.state.colour} onChange={this.handleColourChange} />
				<p></p>
				<FormGroup>
					<Button>Save</Button>&nbsp;
					<Button onClick={this.handleClickCancel}>Cancel</Button>
				</FormGroup>
			</AvForm>
		);
	}
}

export const VolunteerGroupAdd = withTranslation()(VolunteerGroupAddPlain);
