import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';

import { PathList } from './PathList';
import { PathAdd } from './PathAdd';
import { PathDelete } from './PathDelete';
import { PathEdit } from './PathEdit';

export function PathRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}/add`} component={PathAdd} />
			<Route path={`${match.path}/delete/:id`} component={PathDelete} />
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={PathEdit} />
			<AuthorizeRoute path={`${match.path}`} component={PathList} />
		</Switch>
	);
}