import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';

import { ReportedIssueList } from './ReportedIssueList';
/*import { ReportedIssueAdd } from './ReportedIssueAdd';*/
import { ReportedIssueDelete } from './ReportedIssueDelete';
import { ReportedIssueEdit } from './ReportedIssueEdit';

export function ReportedIssueRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			{/*<AuthorizeRoute path={`${match.path}/add`} component={ReportedIssueAdd} />*/}
			<Route path={`${match.path}/delete/:id`} component={ReportedIssueDelete} />
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={ReportedIssueEdit} />
			<AuthorizeRoute path={`${match.path}`} component={ReportedIssueList} />
		</Switch>
	);
}