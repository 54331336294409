import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';

import { PathStateList } from './PathStateList';
import { PathStateAdd } from './PathStateAdd';
import { PathStateDelete } from './PathStateDelete';
import { PathStateEdit } from './PathStateEdit';

export function PathStateRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}/add`} component={PathStateAdd} />
			<Route path={`${match.path}/delete/:id`} component={PathStateDelete} />
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={PathStateEdit} />
			<AuthorizeRoute path={`${match.path}`} component={PathStateList} />
		</Switch>
	);
}