import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';

import { VolunteerSectionList } from './VolunteerSectionList';
import { VolunteerSectionAdd } from './VolunteerSectionAdd';
import { VolunteerSectionDelete } from './VolunteerSectionDelete';
import { VolunteerSectionEdit } from './VolunteerSectionEdit';

export function VolunteerSectionRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}/add`} component={VolunteerSectionAdd} />
			<Route path={`${match.path}/delete/:id`} component={VolunteerSectionDelete} />
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={VolunteerSectionEdit} />
			<AuthorizeRoute path={`${match.path}`} component={VolunteerSectionList} />
		</Switch>
	);
}